<template>
  <div>
    <a-card title="标签配置">
      <a-spin :spinning="loading">
        <a-form :form="form" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }" @submit="handleSubmit">
          <a-row>
            <a-col :span="12">
              <a-form-item label="产品标签长度(mm)">
                <a-input-number v-decorator="['material_label_length', { rules: [{ required: true, message: '请输入产品标签长度' }]}]" style="width: 200px;" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="产品标签宽度(mm)">
                <a-input-number v-decorator="['material_label_width', { rules: [{ required: true, message: '请输入产品标签宽度' }]}]" style="width: 200px;" />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="批次标签长度(mm)">
                <a-input-number v-decorator="['batch_label_length', { rules: [{ required: true, message: '请输入批次标签长度' }]}]" style="width: 200px;" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="批次标签宽度(mm)">
                <a-input-number v-decorator="['batch_label_width', { rules: [{ required: true, message: '请输入批次标签宽度' }]}]" style="width: 200px;" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="库位标签长度(mm)">
                <a-input-number v-decorator="['location_label_length', { rules: [{ required: true, message: '请输入库位标签长度' }]}]" style="width: 200px;" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="库位标签宽度(mm)">
                <a-input-number v-decorator="['location_label_width', { rules: [{ required: true, message: '请输入库位标签宽度' }]}]" style="width: 200px;" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="送货地点标签长度(mm)">
                <a-input-number v-decorator="['address_label_width', { rules: [{ required: true, message: '请输入送货地点标签长度' }]}]" style="width: 200px;" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="送货地点标签宽度(mm)">
                <a-input-number v-decorator="['address_label_length', { rules: [{ required: true, message: '请输入送货地点标签宽度' }]}]" style="width: 200px;" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>

      <div style="margin-top: 32px;">
        <a-popconfirm title="确定保存吗?" @confirm="create">
          <a-button type="primary" :loading="loading">保存</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
  import { labelConfigsList, labelConfigsSave } from '@/api/system';

  export default {
    name: 'SystemConfigs',
    components: {
    },
    data() {
      return {
        loading: false,
        visible: false,
        form: this.$form.createForm(this),
      };
    },
    methods: {
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        labelConfigsList().then(data => {
          this.$nextTick(() => {
            this.form.setFieldsValue({
              material_label_width: data.material_label_width,
              material_label_length: data.material_label_length,
              batch_label_width: data.batch_label_width,
              batch_label_length: data.batch_label_length,
              location_label_width: data.location_label_width,
              location_label_length: data.location_label_length,
              address_label_width: data.address_label_width,
              address_label_length: data.address_label_length,
            })
          })
        }).finally(() => {
          this.loading = false;
        });
      },
      create () {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.confirmLoading = true
            labelConfigsSave(values).then(data => {
              this.$message.success('保存成功')
              this.$store.commit('setBarConfig', values);
            }).finally(() => {
              this.confirmLoading = false
            })
          }
        })
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>

<style scoped>
</style>