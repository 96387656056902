import request from '@/utils/request';


// Permission
export function permissionList(params) {
  return request({ url: `/permissions/`, method: 'get', params })
}

// OrderPrefix
export function orderPrefixList(params) {
  return request({ url: `/order_prefixes/`, method: 'get', params })
}

export function orderPrefixUpdate(data) {
  return request({ url: `/order_prefixes/${data.id}/`, method: 'put', data })
}

// 标签配置信息
export function labelConfigsList(params) {
  return request({ url: `/label/configs/`, method: 'get', params })
}

// 标签配置保存
export function labelConfigsSave(data) {
  return request({ url: `/label/set_configs/`, method: 'post', data })
}

// 系统配置信息
export function systemConfigsList(params) {
  return request({ url: `/system/configs/`, method: 'get', params })
}
